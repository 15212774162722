// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,700,700i&display=swap');
/*@font-face {
    font-family: "Nunito", sans-serif;
    src: url("/fonts/Nunito/Nunito-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Nunito", sans-serif;
    src: url("/fonts/Nunito/Nunito-Italic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Nunito", sans-serif;
    src: url("/fonts/Nunito/Nunito-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Nunito", sans-serif;
    src: url("/fonts/Nunito/Nunito-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}*/
// Variables
@import 'variables';

@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@import "~bootstrap-slider/dist/css/bootstrap-slider.min.css";

$theme-colors: (
    "exsafe": #ff8200
);
$body-bg: #eeeeee;

$custom-file-text: (
    en: "Browse",
    it: "Scegli"
);

// Colori associati al Risk Appetite
$riskappetite_verylow: #95d699;
$riskappetite_low: #fbdf56;
$riskappetite_medium: #ffa138;
$riskappetite_high: #ff5858;
$riskappetite_veryhigh: #ae62d6;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";

@import "~select2/dist/css/select2.min.css";
@import "~select2-theme-bootstrap4/src/select2-bootstrap";

.bg-login {
    background: rgb(237, 237, 237);
    //background: linear-gradient(90deg, rgb(229, 229, 229) 0%, rgb(224, 224, 224) 35%, rgb(237, 237, 237) 100%);
    background: linear-gradient(90deg, rgb(51, 51, 153) 0%, rgb(51, 153, 153) 100%);
}
.nav-item .dropdown-toggle:after { content: none; }
.navbar:first-of-type { z-index: 999; }
.dashboard-section {
    color: $gray-600;
    border-bottom: 3px dashed $gray-500 !important;
}
html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 232px; /* Margin bottom by footer height */
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 232px; /* Set the fixed height of the footer here */
}
@media print {
    body {
        background-color: white;
    }
    .pagebreak {
        page-break-before: always;
    } /* page-break-after works, as well */
    .noprint {
        display:none;
    }
    #canvas-holder {
        /*margin-top: 200px;*/
    }
}

ul.navbar-nav {
    li.active {
        border-bottom: 3px solid theme-color('exsafe');
    }
}
ul.sidebar {
    li {
        padding: 0 1rem;
        margin: 1rem 0;
        a {
            color: $navbar-light-color;
        }
    }
    li.active {
        border-left: 3px solid theme-color('exsafe');
        a {
            color: $navbar-light-active-color;
        }
    }
}

.absolute-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
}
.nav-tabs .nav-link.active {
    background-color: white;
}

.bg-riskappetite-verylow {
    background-color: $riskappetite_verylow;
}
.bg-riskappetite-low {
    background-color: $riskappetite_low;
}
.bg-riskappetite-medium {
    background-color: $riskappetite_medium;
}
.bg-riskappetite-high {
    background-color: $riskappetite_high;
}
.bg-riskappetite-veryhigh {
    background-color: $riskappetite_veryhigh;
}





/* Colori dei rischi - Minimo */

.color_minimum {
    color: rgba(169,206,145, 1);
}
.bg_color_minimum {
    background-color: rgba(169,206,145, 1);
}
.bg_color_transparent_minimum {
    background-color: rgba(169,206,145,0.2);
}
.slider-rangeHighlight.bg_color_minimum {
    background: rgba(169,206,145, 1) !important;
}

/* Colori dei rischi - Trascurabile */

.color_low {
    color: rgba(112,173,71, 1);
}
.bg_color_low {
    background-color: rgba(112,173,71, 1);
}
.bg_color_transparent_low {
    background-color: rgba(112,173,71,0.2);
}
.slider-rangeHighlight.bg_color_low {
    background: rgba(112,173,71, 1) !important;
}

/* Colori dei rischi - Basso */

.color_midlow {
    color: rgba(255,192,0, 1);
}
.bg_color_midlow {
    background-color: rgba(255,192,0, 1);
}
.bg_color_transparent_midlow {
    background-color: rgba(255,192,0,0.2);
}
.slider-rangeHighlight.bg_color_midlow {
    background: rgba(255,192,0, 1) !important;
}

/* Colori dei rischi - Medio */

.color_medium {
    color: rgba(237,125,49, 1);
}
.bg_color_medium {
    background-color: rgba(237,125,49, 1);
}
.bg_color_transparent_medium {
    background-color: rgba(237,125,49,0.2);
}
.slider-rangeHighlight.bg_color_medium {
    background: rgba(237,125,49, 1) !important;
}


/* Colori dei rischi - Alto */

.color_midhigh {
    color: rgba(192,0,0, 1);
}
.bg_color_midhigh {
    background-color: rgba(192,0,0, 1);
    color: white;
}
.bg_color_transparent_midhigh {
    background-color: rgba(192,0,0,0.2);
}
.slider-rangeHighlight.bg_color_midhigh {
    background: rgba(192,0,0, 1) !important;
    color: white;
}

/* Colori dei rischi - Molto Alto */

.color_high {
    color: rgba(145,0,100, 1);
}
.bg_color_high {
    background-color: rgba(145,0,100, 1);
    color: white;
}
.bg_color_transparent_high {
    background-color: rgba(145,0,100,0.2);
}
.slider-rangeHighlight.bg_color_high {
    background: rgba(145,0,100, 1) !important;
    color: white;
}

.slider-track-high {
    background: white !important;
}



.slider-handle {
    /*background-image: none;
    background-color: #ffffff;
    border: 2px solid theme-color('exsafe');*/
    top: -5px;
    margin-left: -15px !important;
    width: 30px;
    height: 30px;
    border: 2px solid white;
}

.select2-container--default
.select2-selection--single {
    border: 1px solid #ced4da;
    padding: 6px 12px;
    height: 2.2rem;
}

.bg-odd {
    background-color: rgba(0, 0, 0, 0.05);
}
